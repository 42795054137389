<template>
	<div class="pane">
		<div class="paneContent">
			<div class="help">
				<i class="material-icons md-48">error</i>
				<br />This page could not be found.
			</div>
		</div>
	</div>
</template>
